import React from "react";

const Footer = () => {
  return (
    <footer className="text-center my-1 py-1 small">
      Developed by: Param Chandarana, Parth Zinzuvadia, Jeet Khunti
    </footer>
  );
};

export default Footer;
